import React from 'react';
import Image from 'next/image';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import * as Types from 'declarations';
import TrekCard from 'components/TrekCard';
import * as HelperUtil from 'lib/helper';
import TripMapComponent from 'components/HomePage/TripMap';
const TripCards = ({
  tripIds,
  trips
}: {
  tripIds: number[];
  trips: Types.App.HomePageTrip[];
}) => {
  const filteredTreks = trips.filter(trip => tripIds.includes(trip.id));
  return <>
			<div className="trek-card-container article-trek-card-container">
				{filteredTreks.map((trek, key) => <TrekCard tripCardProps={HelperUtil.homePageTripToTripCard(trek)} key={key} />)}
			</div>
		</>;
};
const TripMap = ({
  tripIds,
  trips
}: {
  tripIds: number[];
  trips: Types.App.HomePageTrip[];
}) => {
  const locations = trips.map(trip => ({
    latitude: trip.routeLocation.latitude,
    longitude: trip.routeLocation.longitude,
    height: trip.height
  }));
  return <div style={{
    maxWidth: '100%'
  }} data-sentry-component="TripMap" data-sentry-source-file="HTMLContent.tsx">
			<TripMapComponent locations={locations} version={Types.VERSION.PEAKS} data-sentry-element="TripMapComponent" data-sentry-source-file="HTMLContent.tsx" />
		</div>;
};
const HTMLContent = ({
  html,
  id,
  className,
  trips,
  version
}: {
  html: string;
  id?: string;
  className?: string;
  trips?: Types.App.HomePageTrip[];
  version?: Types.VERSION;
}) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if (domNode.type === 'tag' && domNode.name === 'img') {
        return <Image src={domNode.attribs.src} alt={domNode.attribs.alt || !!version ? `${version === Types.VERSION.PEAKS ? 'Guided Peaks' : 'Guided Trekking'}` : ''} width={500} height={500} layout="responsive" className="article-image-replaced" />;
      }
      if (domNode.type === 'tag' && domNode.attribs['data-shortcode']) {
        const shortcode = domNode.attribs['data-shortcode'];
        const tripIds = domNode.attribs['data-ids'].split(',').map(Number);
        const relevantTrips = (trips ?? []).filter(trip => tripIds.includes(trip.id));
        if (shortcode === 'tripcards') {
          return <TripCards tripIds={tripIds} trips={relevantTrips ?? []} />;
        } else if (shortcode === 'tripmap') {
          return <TripMap tripIds={tripIds} trips={relevantTrips ?? []} />;
        }
      }
    }
  };
  return <div id={id} className={className} data-sentry-component="HTMLContent" data-sentry-source-file="HTMLContent.tsx">
			{parse(html, options)}
		</div>;
};
export default HTMLContent;