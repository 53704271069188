// export {}
import * as MantineCore from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import Link from 'next/link';
import { Paper, Text, Title, Button, useMantineTheme, rem } from '@mantine/core';
import * as Types from 'declarations';
import * as DisplayUtil from 'lib/display';
interface CardProps {
  imageURL: string;
  title: string;
  slug: string;
  // category: string
}
const Card = ({
  imageURL,
  title,
  slug
}: CardProps) => {
  return <Paper shadow="md" p="xl" radius="md" style={{
    backgroundImage: `url(${imageURL})`
  }} className={'card'} data-sentry-element="Paper" data-sentry-component="Card" data-sentry-source-file="ArticlesCarousel.tsx">
			<div>
				{/* <Text className={'category'} size="xs">
     {category}
     </Text> */}
				<Title order={4} className={'title'} data-sentry-element="Title" data-sentry-source-file="ArticlesCarousel.tsx">
					<MantineCore.Text lineClamp={6} style={{
          lineHeight: '125%'
        }} data-sentry-element="unknown" data-sentry-source-file="ArticlesCarousel.tsx">
						{title}
					</MantineCore.Text>
				</Title>
			</div>
			<Link href={DisplayUtil.getArticleRoute({
      slug: slug
    } as Types.API.Article)} data-sentry-element="Link" data-sentry-source-file="ArticlesCarousel.tsx">
				<Button variant="white" color="dark" data-sentry-element="Button" data-sentry-source-file="ArticlesCarousel.tsx">
					Read article
				</Button>
			</Link>
		</Paper>;
};
const CardsCarousel = ({
  articleSummaries
}: {
  articleSummaries: {
    title: string;
    slug: string;
    imageId: number;
  }[];
}) => {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const slides = articleSummaries.map(item => <Carousel.Slide key={item.title}>
			<Card imageURL={DisplayUtil.getUploadThumbPath(item.imageId, 'large')} title={item.title} slug={item.slug} />
		</Carousel.Slide>);
  return <div className="articles-carousel" data-sentry-component="CardsCarousel" data-sentry-source-file="ArticlesCarousel.tsx">
			<Carousel slideSize="30%" slideGap="md" align="start" breakpoints={[{
      maxWidth: 'xl',
      slideSize: '30%'
    }, {
      maxWidth: 'lg',
      slideSize: '30%'
    }, {
      maxWidth: 'md',
      slideSize: '43%'
    }, {
      maxWidth: 'sm',
      slideSize: '80%'
    }, {
      maxWidth: 'xs',
      slideSize: '100%'
    }]} data-sentry-element="Carousel" data-sentry-source-file="ArticlesCarousel.tsx">
				{slides}
			</Carousel>
		</div>;
};
export default CardsCarousel;