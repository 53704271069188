import * as MantineCore from '@mantine/core';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import pluralize from 'pluralize';
import NextImage from 'next/image';
import * as React from 'react';
import fs from 'fs';
import path from 'path';
import * as Types from 'declarations';
import * as DataUtil from 'lib/data';
import * as DisplayUtil from 'lib/display';
import * as HelperUtil from 'lib/helper';
import PageTemplate from 'components/PageTemplate';
import AccountNudge from 'components/AccountNudge';
import TripsCarousel from 'components/HomePage/TripsCarousel';
import ArticlesCarousel from 'components/HomePage/ArticlesCarousel';
import TripMap from 'components/HomePage/TripMap';
import HTMLContent from 'components/HTMLContent';
import { withVersion } from '../lib/withVersion';
const getImagesFromDir = (dirPath: string): string[] => {
  const fullPath = path.join(process.cwd(), 'public', dirPath);
  const files = fs.readdirSync(fullPath);
  return files.filter(file => /\.(jpg|jpeg|png|gif)$/i.test(file)).map(file => path.join(dirPath, file));
};
const HeroCarousel = ({
  version,
  imageURLs,
  guideCount,
  expeditionCount,
  tripCount
}: {
  version: Types.VERSION;
  imageURLs: string[];
  guideCount: number;
  expeditionCount: number;
  tripCount: number;
}) => {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const preloadedImages = React.useRef<HTMLImageElement[]>([]);

  // Preload images
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      preloadedImages.current = imageURLs.map(url => {
        const img = new Image();
        img.src = url;
        return img;
      });
    }
  }, [imageURLs]);
  React.useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide(prev => (prev + 1) % imageURLs.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [imageURLs.length]);
  return <div className={'wrapper'} data-sentry-component="HeroCarousel" data-sentry-source-file="index.tsx">
			<div style={{
      backgroundImage: `url(${imageURLs[activeSlide]})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '600px',
      position: 'relative',
      transition: 'background-image .75s ease-in-out'
    }}>
				<MantineCore.Overlay color="#000" opacity={0.6} zIndex={1} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
				<div className={'inner'}>
					<MantineCore.Title className={'title'} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
						{version === Types.VERSION.PEAKS ? <>
								Join a mountain climbing expedition,
								<br />
								climb the world
							</> : 'Find amazing treks, create incredible memories'}
					</MantineCore.Title>
					<MantineCore.Container style={{
          display: 'flex'
        }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
						<MantineCore.Text size="lg" className={'description'} style={{
            display: 'flex',
            margin: '0 auto'
          }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							{version === Types.VERSION.PEAKS ? <div style={{
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center'
            }}>
									<MantineCore.Title order={2} style={{
                textAlign: 'center'
              }}>
										<u>{guideCount.toLocaleString()} guides</u>, with{' '}
										<u>{expeditionCount.toLocaleString()} expeditions</u> on{' '}
										<u>{tripCount} mountains</u>
									</MantineCore.Title>
									{version === Types.VERSION.PEAKS && <p style={{
                marginTop: '16px',
                marginBottom: 0,
                textAlign: 'center'
              }}>
											Guided Peaks helps you find the best mountain climbing and
											mountaineering expeditions around the world.
										</p>}
									<ul>
										<li>
											<Link href="/guides">Find guides</Link> for solo trips or
											join expeditions
										</li>
										<li>
											<Link href="/expeditions">
												Filter the world&apos;s peaks
											</Link>{' '}
											to find your next challenge
										</li>
										<li>
											Explore mountaineering in each{' '}
											<Link href="/countries">country</Link>
										</li>
										<li>
											Read <Link href="/articles">articles</Link> to best
											prepare
										</li>
									</ul>
								</div> : <ul>
									<li>Explore treks around the world</li>
									<li>Filter treks by country, difficulty, cost, and more</li>
								</ul>}
						</MantineCore.Text>
					</MantineCore.Container>
					<div className={'controls'}>
						<AccountNudge variant="button" version={version} data-sentry-element="AccountNudge" data-sentry-source-file="index.tsx" />
					</div>
				</div>
			</div>
		</div>;
};
export type HomePageProps = {
  // treks: Types.App.HomePageTrip[]
  version: Types.VERSION;
  guideCount: number;
  expedCount: number;
  countryCount: number;
  guideInfo: {
    logoId: number;
    slug: string;
    name: string;
  }[];
  latestArticles: Types.API.Article[];
  carouselTrips: Types.App.Types.CarouselCollection[];
  continentSummaries: Types.App.Types.ContinentSummary[];
  peakMeta: Types.App.Types.PeakMeta;
  trekMeta: Types.App.Types.TrekMeta;
  peakLocations: Types.App.Types.HomePagePeakLocation[];
  uniqueGuideLanguages: string[];
  imageUrls: string[];
};
const HomePage = ({
  version,
  guideCount,
  expedCount,
  countryCount,
  guideInfo,
  latestArticles,
  carouselTrips,
  continentSummaries,
  peakMeta,
  trekMeta,
  peakLocations,
  uniqueGuideLanguages,
  imageUrls
}: HomePageProps) => {
  const seo = {
    title: version === Types.VERSION.TREKKING ? 'Find guided treks - Guided Trekking' : 'Find Climbing & Mountaineering Expeditions Globally - Guided Peaks',
    keywords: 'mountaineering,mountain climbing,expeditions,guided expeditions,guided climbing,guided mountaineering',
    description: version === Types.VERSION.TREKKING ? 'Curated guide of treks for hikers, walkers, & trekkers. Information for each route to compare & find your best next route.' : 'Explore the best mountain climbing & mountaineering expeditions around the world. Join guided climbs, discover mountains, & prepare for your next adventure.',
    canonical: `https://www.guided${version === Types.VERSION.TREKKING ? 'trekking' : 'peaks'}.com`,
    siteName: version === Types.VERSION.TREKKING ? 'Guided Trekking' : 'Guided Peaks',
    images: [{
      url: HelperUtil.getDefaultImage(version),
      type: 'image/jpeg'
    }]
  };
  const headerMT = 'xl';
  const headerMB = 'lg';
  return <>
			<NextSeo title={seo.title} description={seo.description} canonical={seo.canonical} openGraph={{
      url: seo.canonical,
      title: seo.title,
      description: seo.description,
      images: seo.images,
      siteName: seo.siteName,
      type: 'website',
      locale: 'en_US'
    }} additionalMetaTags={[{
      name: 'keywords',
      content: seo.keywords
    }]} data-sentry-element="NextSeo" data-sentry-source-file="index.tsx" />

			<PageTemplate isFullWidth={true} isFullHeight={true} isWithoutTopMargin={true} data-sentry-element="PageTemplate" data-sentry-source-file="index.tsx">
				<div id="homepage">
					<HeroCarousel version={version} imageURLs={imageUrls} guideCount={guideCount} expeditionCount={version === Types.VERSION.PEAKS ? peakMeta.expedCount : -1} tripCount={version === Types.VERSION.PEAKS ? peakMeta.tripCount : trekMeta.tripCount} data-sentry-element="HeroCarousel" data-sentry-source-file="index.tsx" />

					<div style={{
          maxWidth: '1100px',
          margin: '0 auto',
          paddingLeft: '8px',
          paddingRight: '8px'
        }}>
						<MantineCore.Grid mt="xl" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							<MantineCore.Grid.Col span={12} md={8} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
								{/* Content for the 2/3 column */}
								{version === Types.VERSION.PEAKS ? <>
										<p>
											Guided Peaks is for mountaineers, looking for their next
											climbing expedition.
										</p>
										<p>
											It saves you time searching all over the internet, first
											for information on what mountain is best - and then the
											for guides.
										</p>
										<p>
											For each mountain, see what guides offer expeditions and
											compare prices. Then check out their guide profiles for
											reviews and contact details.
										</p>
									</> : <>
										<p>
											Guided Trekking is for hikers, looking for their next
											great trek.
										</p>
										<p>
											It saves you time searching all over the internet, first
											for information on what trek is best - and then the for
											guides.
										</p>
										<p>
											For each trek, see what guides offer trips and compare
											prices. Then check out their guide profiles for reviews
											and contact details.
										</p>
									</>}
							</MantineCore.Grid.Col>

							<MantineCore.Grid.Col span={12} md={4} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
								<MantineCore.Title order={3} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
									Guided{' '}
									{version === Types.VERSION.PEAKS ? 'Peaks' : 'Trekking'} is
									the Airbnb for
								</MantineCore.Title>
								<ul>
									<li>
										<MantineCore.Text td="line-through" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
											{version === Types.VERSION.PEAKS ? 'Mountains' : 'Treks'}
										</MantineCore.Text>
									</li>
									<li>
										<MantineCore.Text td="line-through" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
											Guides
										</MantineCore.Text>
									</li>
									<li>
										<MantineCore.Text td="line-through" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
											Expeditions
										</MantineCore.Text>
									</li>
									<li>
										<MantineCore.Text fw={700} data-sentry-element="unknown" data-sentry-source-file="index.tsx">Your next trip</MantineCore.Text>
									</li>
								</ul>
							</MantineCore.Grid.Col>
						</MantineCore.Grid>

						<MantineCore.Title order={2} mt={headerMT} mb={headerMB} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							Explore{' '}
							{version === Types.VERSION.PEAKS ? 'Mountaineering' : 'Trekking'}{' '}
							Destinations by Country
						</MantineCore.Title>
						<MantineCore.Grid data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							<MantineCore.Grid.Col span={12} sm={6} className="trip-map-col" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
								<TripMap locations={peakLocations as {
                latitude: number;
                longitude: number;
                height: number | null;
              }[]} version={version} data-sentry-element="TripMap" data-sentry-source-file="index.tsx" />
							</MantineCore.Grid.Col>
							<MantineCore.Grid.Col span={12} sm={6} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
								<p>
									There are{' '}
									{version === Types.VERSION.PEAKS ? 'expeditions' : 'treks'} in{' '}
									{countryCount} countries.
								</p>{' '}
								<p>
									{version === Types.VERSION.PEAKS ? 'Climb' : 'Trek'} around
									the world and...
									<ul>
										<li>
											experience different climates - letting you{' '}
											{version === Types.VERSION.PEAKS ? 'climb' : 'trek'} year
											round
										</li>
										{version === Types.VERSION.PEAKS && <li>try different climbing styles</li>}

										<li>
											find hugely different price points & permit requirements
										</li>
										<li>
											explore uniquely different interesting local cultures to
											experience
										</li>
									</ul>
								</p>
								{version === Types.VERSION.PEAKS && <p>
										Check out out the <Link href="/countries">countries</Link>{' '}
										pages for detailed information on each region.
									</p>}
							</MantineCore.Grid.Col>
						</MantineCore.Grid>

						<div style={{
            textAlign: 'center',
            fontSize: 'large'
          }}>
							<MantineCore.Title order={2} mt={headerMT} mb={headerMB} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
								{version === Types.VERSION.PEAKS ? 'Explore Mountain Climbing Expeditions on Global Peaks' : 'Treks you can join - find a trip'}
							</MantineCore.Title>

							<p>
								<Link href="/expeditions" data-sentry-element="Link" data-sentry-source-file="index.tsx">
									Explore {expedCount}{' '}
									{version === Types.VERSION.PEAKS ? 'peaks' : 'treks'}
								</Link>
								, with more added constantly
								{version === Types.VERSION.PEAKS ? <>
										<li>
											ranging from {peakMeta.lowestPeak.toLocaleString()} -{' '}
											{peakMeta.highestPeak.toLocaleString()} meters.
										</li>
										<li>
											Mixed difficulties:{' '}
											{version === Types.VERSION.PEAKS && <>
													{peakMeta.difficulties.map((diff, idxDiffs) => <span key={idxDiffs}>
															{idxDiffs > 0 && ', '}
															{HelperUtil.convertSimpleToFrench(diff).abbreviation}
														</span>)}
												</>}
											{version as Types.VERSION === Types.VERSION.TREKKING && <>
													{trekMeta.difficulties.lowest}/10 -{' '}
													{trekMeta.difficulties.highest}/10
												</>}
										</li>
										<li>
											On {continentSummaries.length} continents, and{' '}
											{continentSummaries.reduce((total, {
                    countries
                  }) => total + countries.length, 0)}{' '}
											countries
										</li>
									</> : <>
										<li>
											ranging from {trekMeta.shortestDays.toLocaleString()} days
											to - {trekMeta.longestDays.toLocaleString()} days long
										</li>
										<li>
											Mixed difficulties: {trekMeta.difficulties.lowest}/10 to{' '}
											{trekMeta.difficulties.highest}/10
										</li>
										<li>
											On {continentSummaries.length} continents, and{' '}
											{continentSummaries.reduce((total, {
                    countries
                  }) => total + countries.length, 0)}{' '}
											<Link href="/countries">countries</Link>
										</li>
									</>}
							</p>
							<MantineCore.Box mt="xl" style={{
              textAlign: 'center'
            }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
								<Link href="/expeditions" data-sentry-element="Link" data-sentry-source-file="index.tsx">
									<MantineCore.Button data-sentry-element="unknown" data-sentry-source-file="index.tsx">
										Explore all{' '}
										{version === Types.VERSION.PEAKS ? 'mountains' : 'treks'}
									</MantineCore.Button>
								</Link>
							</MantineCore.Box>
							<MantineCore.Text size="xl" mt={headerMT} mb={headerMB} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
								Here are some ideas to get you started:
							</MantineCore.Text>
						</div>

						<div>
							{carouselTrips.filter(tripCollection => tripCollection.trips.length > 0).map((tripCollection, idx) => {
              return <div key={idx} style={{
                display: 'grid'
              }}>
											<MantineCore.Title order={3} mt={headerMT} mb={headerMB}>
												{tripCollection.title}
											</MantineCore.Title>
											<MantineCore.Text mb="lg">
												<HTMLContent html={tripCollection.blurb} />
											</MantineCore.Text>
											<TripsCarousel version={version} trips={tripCollection.trips} altBase={tripCollection.title} />
										</div>;
            })}
						</div>

						{/* // todo: make this for trekking too */}
						{version === Types.VERSION.PEAKS && <>
								<MantineCore.Title order={2} mt={headerMT} mb={headerMB} id="findaguide">
									Find a guide
								</MantineCore.Title>
								<p>
									We have {guideCount} guides, with more joining every week.
								</p>
								<p>
									Guides vary a lot, from what languages they speak (
									{uniqueGuideLanguages.join(', ')}), experience,
									qualifications, ratings on tripadvisor, price, and most
									importantly how they resonate with you.
								</p>
								<div style={{
              textAlign: 'center'
            }}>
									<MantineCore.Text size="xl" mt={headerMT} mb={headerMB}>
										Find one offering the mountains you&apos;re interested in,
										and with a profile that resonates with you:
									</MantineCore.Text>
									<Link href="/guides">
										<MantineCore.Button>Explore all guides</MantineCore.Button>
									</Link>
								</div>
								<MantineCore.SimpleGrid cols={4} spacing="lg" mt={'xl'} mb={'xl'} breakpoints={[{
              maxWidth: 'md',
              cols: 3,
              spacing: 'md'
            }, {
              maxWidth: 'sm',
              cols: 2,
              spacing: 'sm'
            }, {
              maxWidth: 'xs',
              cols: 2,
              spacing: 'sm'
            }]}>
									{guideInfo.filter((_, idxGuides) => idxGuides < 12).map((guideInfo, idxGuideLogos) => <div key={idxGuideLogos} style={{
                height: '100px',
                display: 'flex'
              }}>
												<Link href={DisplayUtil.getGuideRoute(guideInfo as unknown as Types.API.GuideCompany)} style={{
                  display: 'contents'
                }}>
													<MantineCore.Tooltip label={guideInfo.name} inline withArrow>
														<NextImage src={DisplayUtil.getUploadThumbPath(guideInfo.logoId, 'medium')} alt={guideInfo.name} width={160} height={100} style={{
                      maxHeight: '100%',
                      width: 'auto',
                      borderRadius: '8px',
                      maxWidth: '100%',
                      height: 'auto',
                      margin: '0 auto'
                    }} />
													</MantineCore.Tooltip>
												</Link>
											</div>)}
								</MantineCore.SimpleGrid>
							</>}

						{/* // todo: articles section for trekking too */}
						{version === Types.VERSION.PEAKS && <>
								<MantineCore.Title order={2} mt={headerMT} mb={headerMB}>
									Explore articles to get you closer to your mountaineering
									goals
								</MantineCore.Title>
								<div style={{
              display: 'grid'
            }}>
									<ArticlesCarousel articleSummaries={latestArticles.map(article => ({
                title: article.title,
                slug: article.slug,
                imageId: article.liveJson.coverImage as number
              }))} />
								</div>
								<div style={{
              textAlign: 'center'
            }}>
									<MantineCore.Box mt={headerMT} mb={headerMB}>
										<Link href="/guides">
											<MantineCore.Button>
												Prepare for your mountaineering adventure - Explore all
												articles
											</MantineCore.Button>
										</Link>
									</MantineCore.Box>
								</div>
							</>}
						<MantineCore.Box mt="xl" mb="xl" style={{
            margin: '0 auto'
          }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
							<div style={{
              maxWidth: '360px',
              margin: '0 auto'
            }}>
								<AccountNudge variant="modal" version={version} data-sentry-element="AccountNudge" data-sentry-source-file="index.tsx" />
							</div>
						</MantineCore.Box>
					</div>
				</div>
			</PageTemplate>
		</>;
};
export const getServerSideProps = withVersion(async (context: {
  version?: Types.VERSION;
}) => {
  const {
    trips,
    guideInfo,
    guideCount,
    carouselTrips,
    continentSummaries,
    peakMeta,
    trekMeta,
    peakLocations,
    uniqueGuideLanguages
  } = await DataUtil.getHomePageData(context?.version);
  const latestArticles = await DataUtil.getArticles(context.version, true, 4);
  const uniqueCountries = [...new Set(trips.flatMap(trip => trip.countries))];
  const imageUrls = getImagesFromDir(`/static/homepage-carousel/${context.version === Types.VERSION.PEAKS ? 'peaks' : 'trekking'}`);
  return {
    props: {
      guideCount,
      countryCount: uniqueCountries.length,
      guideInfo,
      version: context?.version,
      latestArticles,
      carouselTrips,
      continentSummaries,
      peakMeta,
      trekMeta,
      peakLocations,
      uniqueGuideLanguages,
      imageUrls
    }
  };
});
export default HomePage;